<template>
  <div class="demo-date-picker">
    <div class="block">
      <span class="demonstration">选择日期</span>
      <el-date-picker
        v-model="date"
        type="daterange"
        range-separator="To"
        start-placeholder="请选择开始日期"
        end-placeholder="请选择结束日期"
        value-format="YYYY-MM-DD"
      />
    </div>
  </div>
  <button @click="getChart">获取数据</button>
  <div class="home">
    <div id="charts" ref="charts"></div>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref, inject, shallowRef } from 'vue'
const formatDate = date => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const echarts = inject('$echarts')
const date = ref([
  formatDate(new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 365)),
  formatDate(new Date())
])
const xAxisData = ref({})
const seriesData = ref([])
const charts = ref(null)
const chartsDom = shallowRef(null)
const shortcuts = [
  {
    text: 'Last week',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: 'Last month',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  },
  {
    text: 'Last 3 months',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    }
  }
]
const initCharts = () => {
  chartsDom.value = echarts.init(charts.value)
  chartsDom.value.setOption({
    xAxis: {
      type: 'category',
      data: xAxisData.value.data,
      axisLabel: {
        rotate: 20
      }
    },
    yAxis: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' }
    },
    legend: {},
    series: seriesData.value
  })
}
const initData = () => {
  return new Promise(resolve => {
    fetch('/data.json')
      .then(res => {
        return res.json()
      })
      .then(data => {
        xAxisData.value = data.xAxis
        seriesData.value = data.series

        resolve('done')
      })
  })
}
const initAll = async () => {
  await initData()
  initCharts()
}
window.addEventListener('resize', () => {
  chartsDom.value.resize()
})
initAll()
const getChart = () => {
  axios
    .get('/api/getchartdata', {
      headers: {
        authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoieWFuZ2hhbzEiLCJpYXQiOjE3MTU3NjYzMTUsImV4cCI6MTgwMjE2NjMxNX0.LonScQNdo6ytG5gVorbqc8UBb6OaOgUe56Xl01nmMKo`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      // params: qs.stringify({ date: date.value }, { arrayFormat: 'repeat' })
      params: { data: date.value }
    })
    .then(res => {
      console.log(res)
    })
}
</script>

<style>
#charts {
  width: 100%;
  height: 500px;
  margin-top: 10px;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
