import axios from 'axios'
// import store from '../store'
// import router from '../router'
// import { showLoadingToast, closeToast, showFailToast } from 'vant'
// import { CLEAR_LOGINSTATUS } from '../store/mutation-types'
// allowMultipleToast()
const pendingMap = new Map()
const cache = new Map()
const expire_time = 1000 * 20
const LoadingInstance = {
  _count: 0
}

/**
 * 处理异常
 * @param {*} error
 */
function httpErrorStatusHandle(error) {
  //HTTP状态出错时通过error.response.status判断
  //有缓存导致取消时error.message.data为对象
  //其它情况下error.message为字符串对象
  let message = ''
  if (error && error.response) {
    switch (error.response.status) {
      case 302:
        message = '接口重定向了！'
        break
      case 400:
        message = error.response.data.message
        break
      case 401:
        message = ''
        console.log(`${new Date().toLocaleTimeString()}触发token无效401错误`)
        // store.commit(CLEAR_LOGINSTATUS)
        // store.dispatch('loginIsValid')
        // message = '您未登录，或者登录已经超时，请先登录！'
        break
      case 403:
        message = '您没有权限操作！'
        break
      case 404:
        message = `请求地址出错: ${error.response.config.url}`
        break // 在正确域名下
      case 408:
        message = '请求超时！'
        break
      case 409:
        message = '系统已存在相同数据！'
        break
      case 500:
        message = '服务器内部错误！'
        break
      case 501:
        message = '服务未实现！'
        break
      case 502:
        message = '网关错误！'
        break
      case 503:
        message = '服务不可用！'
        break
      case 504:
        message = '服务暂时无法访问，请稍后再试！'
        break
      case 505:
        message = 'HTTP版本不受支持！'
        break
      default:
        message = '异常问题，请联系管理员！'
        break
    }
  }
  if (!error.message.data && error.message.includes('timeout')) {
    message = '网络请求超时！'
  }
  if (!error.message.data && error.message.includes('Network')) {
    message = window.navigator.onLine ? '服务端异常！' : '您断网了！'
  }
  // message ? showFailToast(`${message}`) : ''
}

/**
 * 关闭Loading层实例
 * @param {*} _options
 */
function closeLoading(_options) {
  if (_options.loading && LoadingInstance._count > 0) {
    LoadingInstance._count--
  }
  if (LoadingInstance._count === 0) {
    // closeToast(showLoadingToast)
  }
}

/**
 * 储存每个请求的唯一cancel回调, 以此为标识
 * @param {*} config
 */
function addPending(config) {
  //调用方法将当前请求变成为字符串
  const pendingKey = getPendingKey(config)
  //设置一个token
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel)
      }
    })
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config) {
  //调用方法将当前请求变成为字符串
  const pendingKey = getPendingKey(config)
  // 如果当前队列「MAP结构」中已有
  if (pendingMap.has(pendingKey)) {
    // 获取添加队列方法中 CancelToken返回的cancel办法
    const cancelToken = pendingMap.get(pendingKey)
    cancelToken(pendingKey)
    // 删除后移除「MAP」结构
    pendingMap.delete(pendingKey)
    // }
  }
}

/**
 * 生成唯一的每个请求的唯一key
 * @param {*} config
 * @returns
 */
function getPendingKey(config) {
  let { url, method, params, data } = config
  if (typeof data === 'string') {
    data = JSON.parse(data) // response里面返回的config.data是个字符串对象
  }
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&')
}
export default (axiosConfig, customOptions, loadingOptions) => {
  const service = axios.create({
    //baseURL: import.meta.env.VITE_APP_API_PREFIX, // 设置统一的请求前缀
    baseURL: process.env.VUE_APP_API_BASE + '', // 设置统一的请求前缀
    timeout: 1000 * 10 // 设置统一的超时时长
  })

  // 自定义配置
  let custom_options = Object.assign(
    {
      repeat_request_cancel: false, // 是否开启取消重复请求, 默认为 true
      loading: true, // 是否开启loading层效果, 默认为false
      reduct_data_format: true, // 是否开启简洁的数据结构响应, 默认为true
      error_message_show: true, // 是否开启接口错误信息展示,默认为true
      code_message_show: true, // 是否开启code不为0时的信息提示, 默认为false
      cache: false //接口是否需要缓存
    },
    customOptions
  )

  // 请求拦截
  service.interceptors.request.use(
    config => {
      // const token = store.getters.getToken
      // if (token) {
      //   config.headers['Authorization'] = `Bearer ${token}`
      // }
      //若不需要取消重复请求，上一次请求的addPending没有执行，即pendingMap中没有值，removePending没有进入if里面执行，和没执行一样
      custom_options.repeat_request_cancel && removePending(config)
      //若有取消重复请求的需求，将此请求添加至队列
      custom_options.repeat_request_cancel && addPending(config)
      // 如果有缓存的需求
      if (custom_options.cache) {
        const key = getPendingKey(config)
        const time = new Date().getTime()
        const data = cache.get(key)
        // 如果缓存未过期
        if (data && time - data.expire < expire_time) {
          // 在需要取消重复请求的情况下,可能已经拥有了cancelToken取消函数，可直接返回缓存
          const cancelToken = pendingMap.get(key)
          if (cancelToken) {
            console.log('需要取消重复请求的情况下返回缓存结果')
            cancelToken(data)
          } else {
            // 在没有取消重复请求的情况下,「pendingMap」并没有对应的取消函数,则需要创建一个axios.CancelToken取消函数，用于返回缓存
            console.log('不需要取消重复请求的情况下返回缓存结果')
            let cancelFun
            config.cancelToken =
              config.cancelToken ||
              new axios.CancelToken(cancel => {
                cancelFun = cancel
              })
            cancelFun(data)
          }
        }
      }
      // 创建loading实例
      if (custom_options.loading) {
        LoadingInstance._count++
        if (LoadingInstance._count === 1) {
          // showLoadingToast(Object.assign({ message: '加载中...', forbidClick: true, duration: 0 }, loadingOptions))
        }
      }

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  // 响应拦截
  service.interceptors.response.use(
    response => {
      removePending(response.config)
      custom_options.loading && closeLoading(custom_options) // 关闭loading
      if (custom_options.code_message_show && response.data.code !== 0) {
        typeof response.data.msg === 'string' && alert(`${response.data.msg}`)
        // typeof response.data.message === 'string'
        //此情况为有多个错误，此时message为对象，默认始终取第一个value显示错误
        // if (typeof response.data.message === 'object') {
        //   // showFailToast(Object.values(response.data.message)[0])
        // }
        return Promise.reject(response.data) // code不等于200, 未传递参数或参数不正确，不进入axios响应拦截器
      }
      //如果需要缓存结果且请求方式为get则缓存数据
      if (custom_options.cache && response.config.method === 'get') {
        const key = getPendingKey(response.config)
        let data = {
          expire: new Date().getTime(),
          data: response.data
        }
        cache.set(key, data)
      }
      // 第二个参数中的reduct_data_format,是否只返回响应中的data层
      return custom_options.reduct_data_format ? response.data : response
    },
    error => {
      console.log('进入错误', error)
      // 删除队列中的请求
      error.config && removePending(error.config)
      custom_options.loading && closeLoading(custom_options) // 关闭loading
      custom_options.error_message_show && httpErrorStatusHandle(error) // 处理错误状态码
      if (axios.isCancel(error)) {
        if (typeof error.message.expire == 'number') {
          return Promise.resolve(error.message.data)
        } else {
          // showFailToast(`请勿重复点击`)
          return Promise.reject('您请求过于频繁')
        }
      }

      // else if (error.response.data.status === 2) {
      // 假设并发请求两次，那么会执行两次以下代码
      // 需要判断是否拥有router.currentRoute.query.url，否则传递过router.currentRoute.fullPath之后，再次传递router.currentRoute.fullPath，将会导致无法正确跳转到相应路由地址
      // let url = router.currentRoute.query.url ? router.currentRoute.query.url : router.currentRoute.fullPath
      // console.log(`token过期，我传递的token是${url}`)
      // 传递的url将会成为登陆成功后跳转的地址
      // store.dispatch('signouts', url)
      // }
      return Promise.reject(error.response)
    }
  )

  return service(axiosConfig)
}
