<template>
  <div id="app">
    <template v-if="store.isLogin">
      <el-container class="container">
        <el-aside class="aside">
          <Aside></Aside>
        </el-aside>
        <el-main>
          <el-config-provider :locale="zhCn">
            <router-view></router-view>
          </el-config-provider>
        </el-main>
      </el-container>
    </template>
    <Login v-else></Login>
  </div>
  <!-- <template>
    <h1>登录账号</h1>
    <form id="form" method="post" @submit.prevent="submitLogin">
      <div>
        <label for="username">请输入账号:</label>
        <input type="text" id="username" v-model="submitLoginForms.username" />
      </div>
      <div>
        <label for="username">请输入密码:</label>
        <input
          type="password"
          id="username"
          v-model="submitLoginForms.password"
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  </template> -->
  <!-- <template>
    <h1>注册账号</h1>
    <form id="form" method="post" @submit.prevent="submitForm">
      <div>
        <label for="username">请输入账号:</label>
        <input type="text" id="username" v-model="form.username" />
      </div>
      <div>
        <label for="username">请输入密码:</label>
        <input type="password" id="username" v-model="form.password" />
      </div>
      <button @click="submit" type="submit">Submit</button>
    </form>
  </template> -->
  <!-- <template>
    <h1>提交表单</h1>
    <form @submit.prevent="submits">
      <div>
        <label for="date"></label>
        <input type="date" v-model="forms.date" name="date" />
      </div>
      <div>
        <label for="ft3"></label>
        <input type="text" v-model="forms.ft3" name="ft3" />
      </div>
      <div>
        <label for="ft4"></label>
        <input type="text" v-model="forms.ft4" name="ft4" />
      </div>
      <div>
        <label for="tsh"></label>
        <input type="text" v-model="forms.tsh" name="tsh" />
      </div>
      <div>
        <label for="tr_ab"></label>
        <input type="text" v-model="forms.tr_ab" name="tr_ab" />
      </div>
      <button type="submit">Submit</button>
    </form>
  </template> -->
</template>
<script setup>
import Login from './views/Login'
import Aside from './components/Aside'
import { ElConfigProvider } from 'element-plus'
import axios from 'axios'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { reactive, ref } from 'vue'
import { useUsersStore } from './store/store'
const store = useUsersStore()
const token = ref('')
const isLogin = ref(false)
const form = reactive({
  username: '',
  password: ''
})
const submitLoginForms = reactive({
  username: '',
  password: ''
})
const forms = reactive({
  date: '',
  ft3: '',
  ft4: '',
  tsh: '',
  tr_ab: ''
})
const submit = () => {
  axios.post('/api/register', form).then(res => {
    if (res.data.code === 200) {
      alert(res.data.msg)
    }
  })
}
const submits = () => {
  axios
    .post('/api/senddata', forms, {
      headers: {
        authorization: `Bearer ${token.value}`
      }
    })
    .then(res => {
      console.log(res)
    })
}
const submitLogin = () => {
  axios.post('/api/login', submitLoginForms).then(res => {
    if (res.data.code === 1) {
      console.log(res.data.data.token)
      token.value = res.data.data.token
      alert(res.data.msg)
    } else {
      alert(res.data.msg)
    }
  })
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app,
.container {
  height: 100%;
}
</style>
