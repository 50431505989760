import axios from './axios'

export default {
  register(data) {
    return axios({
      method: 'post',
      url: '/api/register',
      data
    })
  },
  login(data) {
    return axios({
      method: 'post',
      url: '/api/login',
      data
    })
  }
}
