import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, form, next) => {
  if (!localStorage.getItem('token') && to.path !== '/login') {
    console.log('未登录状态，前往登录页面')
    next({ path: '/login' })
  } else {
    next()
  }
})
export default router
