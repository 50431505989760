<template>
  <div class="center">
    <h1>登录/注册</h1>
    <div class="logon">
      <div :class="overlaylong">
        <div class="overlaylong-Signin" v-if="disfiex == 0">
          <h2 class="overlaylongH2">登录</h2>
          <input type="text" placeholder="用户名" v-model="form.username" />
          <input type="password" placeholder="密码" v-model="form.password" />
          <h3>忘记密码？</h3>
          <button class="inupbutton" @click="login">登录</button>
        </div>
        <div class="overlaylong-Signup" v-if="disfiex == 1">
          <h2 class="overlaylongH2">注册账户</h2>
          <input type="text" placeholder="用户名" />
          <input type="text" placeholder="密码" />
          <button class="inupbutton" @click="register">注册</button>
        </div>
      </div>
      <div :class="overlaytitle">
        <div class="overlaytitle-Signin" v-if="disfiex == 0">
          <h2 class="overlaytitleH2">你好，朋友！</h2>
          <p class="overlaytitleP">点击注册账户吧</p>
          <div class="buttongohs" @click="Signin">注册</div>
        </div>
        <div class="overlaytitle-Signup" v-if="disfiex == 1">
          <h2 class="overlaytitleH2">欢迎回来</h2>
          <p class="overlaytitleP">
            为了与我们保持联系，请使用您的个人信息登录
          </p>
          <div class="buttongohs" @click="Signup">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useUsersStore } from '../store/store'
const router = useRouter()
const $axios = inject('$axios')
const store = useUsersStore()
console.log(store.isLogin)
const overlaylong = ref('overlaylong')
const overlaytitle = ref('overlaytitle')
const disfiex = ref(0)
const form = reactive({
  username: '',
  password: ''
})
const Signin = () => {
  overlaylong.value = 'overlaylongleft'
  overlaytitle.value = 'overlaytitleright'
  setTimeout(() => {
    disfiex.value = 1
  }, 200)
}
const Signup = () => {
  overlaylong.value = 'overlaylongright'
  overlaytitle.value = 'overlaytitleleft'
  setTimeout(() => {
    disfiex.value = 0
  }, 200)
}
const register = () => {
  console.log('注册')
}
const login = () => {
  $axios
    .login(form)
    .then(res => {
      alert(res.msg)
      localStorage.setItem('token', res.data.token)
      store.$patch(state => {
        state.isLogin = true
      })
      router.push({ name: 'home', replace: true })
    })
    .catch(e => {
      console.log(e)
    })
}
</script>

<style scoped>
.center {
  width: 100%;
  height: 100%;
  background-image: url('/IMG_0413.jpeg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1 {
  font-size: 30px;
  color: black;
}

.logon {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /* position: relative;
		overflow: hidden; */
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  margin-top: 20px;
  display: flex;
  background: -webkit-linear-gradient(right, #4284db, #29eac4);
}

.overlaylong {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaylongright {
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 100%;
  background-color: #fff;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaytitle {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlaytitleH2 {
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleP {
  font-size: 15px;
  color: #fff;
  margin-top: 20px;
}

.overlaytitleleft {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitleright {
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-100%);
  transition: transform 0.6s ease-in-out;
}

.overlaytitle-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaytitle-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttongohs {
  width: 180px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  margin-top: 40px;
}

.overlaylongH2 {
  font-size: 25px;
  color: black;
  width: 250px;
}

.overlaylong-Signin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlaylong-Signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 10px 0;
  width: 240px;
}
h3 {
  font-size: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.inupbutton {
  background-color: #29eac4;
  border: none;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-top: 30px;
}
</style>
