import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as echarts from 'echarts'
import axios from './api/index'

const pinpa = createPinia()
const app = createApp(App)

app.provide('$echarts', echarts)
app.provide('$axios', axios)

app.use(pinpa).use(router).use(ElementPlus).mount('#app')
